<template>
  <div
    class="d-flex align-center mr-sm-8 mb-5 cursor-pointer download-document"
    :id="`download-document-${index}`"
    @click="downloadDocument(document)"
  >
    <div class="d-flex align-center">
      <Paper class="paper-icon" :color="$vuetify.theme.dark ? '#ffffff' : '#000000'" />
    </div>
    <div class="d-flex flex-column ml-4 mobile-text document-text">
      <span>{{ $t(document.title) }}</span>
      <span class="grey--text xs-text">PDF</span>
    </div>
    <v-icon class="ml-2">mdi-chevron-right</v-icon>
    <div
      v-if="documentLoading"
      class="disabled-download backgroundDark"
    />
  </div>
</template>

<script>
import Paper from '@/assets/icons/svg/Paper.vue';
import pdpDefaultDocs from '@/config/pdpDefaultDocs';
import { getDocumentTemplateUrl } from '@/api/documents';

export default {
  name: 'ProductDocument',
  components: {
    Paper,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      documentLoading: false,
    };
  },
  computed: {
    productId() {
      return this.$route.params.product;
    },
  },
  methods: {
    documentTitle(document) {
      const { documentTemplateId, documentKey } = document;
      const translationKey = documentTemplateId.replace(/_/g, '.');
      return `${translationKey}.${documentKey}.title`;
    },
    documentDownloadTitle(document) {
      const { documentTemplateId, title } = document;
      const pdpDefaultDocIds = pdpDefaultDocs.map((item) => item.documentTemplateId);
      if (pdpDefaultDocIds.includes(documentTemplateId)) {
        return `${this.$t(this.documentTitle(document))}.pdf`;
      }
      return `${title}.pdf`;
    },
    async downloadDocument(documentInfo) {
      const { documentTemplateId, documentKey } = documentInfo;
      this.documentLoading = true;
      const input = {
        documentTemplateId,
        documentKey,
        assetId: this.productId,
      };
      const response = await getDocumentTemplateUrl(input);
      const link = document.createElement('a');
      link.download = this.documentDownloadTitle(documentInfo);
      link.href = response.pdfInBase64;
      link.click();
      this.documentLoading = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.paper-icon {
  transform: scale(1.5);
}
.download-document {
  position: relative;
}
.disabled-download {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.document-text {
  text-align: left;
}

@media (max-width: 600px) {
  .xs-text {
    font-size: 8px !important;
  }
  .mobile-text {
    font-size: 10px;
  }
}
</style>
